import axios from 'axios'
import AuthService from '@/services/auth.service'
import getEnv from '@/util/env'
import EventBus from '@/util/EventBus'

let API_URL = ''

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
export class Service {
  constructor () {
    this.authService = new AuthService()
    API_URL = getEnv('backend_endpoint') + 'api/'
  }

  async get (path, params = {}) {
    const validateRefreshtoken = await this.authService.validateRefreshToken()

    if (!validateRefreshtoken) {
      this.authService.clearLocalStorageUserData()
    }

    const response = await axios.get(API_URL + path, {
      headers: this.authHeader(),
      params: params,
      validateStatus: (status) => {
        return this.checkRequestError(status)
      },
    })

    await this.checkToken(response)

    return response.data
  }

  async post (path, data) {
    const validateRefreshtoken = await this.authService.validateRefreshToken()

    if (!validateRefreshtoken) {
      this.authService.clearLocalStorageUserData()
    }

    const response = await axios.post(API_URL + path, data, {
      headers: this.authHeader(),
      validateStatus: (status) => {
        return this.checkRequestError(status)
      },
    })

    await this.checkToken(response)

    return response.data
  }

  async put (path, data) {
    const validateRefreshtoken = await this.authService.validateRefreshToken()

    if (!validateRefreshtoken) {
      this.authService.clearLocalStorageUserData()
    }

    const response = await axios.put(API_URL + path, data, {
      headers: this.authHeader(),
      validateStatus: (status) => {
        return this.checkRequestError(status)
      },
    })

    await this.checkToken(response)

    return response.data
  }

  async delete (path) {
    const validateRefreshtoken = await this.authService.validateRefreshToken()

    if (!validateRefreshtoken) {
      this.authService.clearLocalStorageUserData()
    }

    const response = await axios.delete(API_URL + path, {
      headers: this.authHeader(),
      validateStatus: (status) => {
        return this.checkRequestError(status)
      },
    })

    await this.checkToken(response)

    return response.data
  }

  async checkToken (response) {
    if (response.data.status === 'Token is Expired' || response.data.status === 'Token is Invalid') {
      await this.authService.logout()
      location.href = '/#/login'
      // eslint-disable-next-line no-throw-literal
      throw { message: 'Auth token expired' }
    }
  }

  authHeader () {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user && user.token) {
      // for Node.js Express back-end
      return { Authorization: 'Bearer ' + user.token }
    } else {
      return {}
    }
  }

  headers () {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user && user.token) {
      // for Node.js Express back-end
      return { Authorization: 'Bearer ' + user.token, 'Content-Type': 'multipart/form-data' }
    } else {
      return {}
    }
  }

  checkRequestError (status) {
    switch (status) {
      case 400:
        break
      case 422:
        break
    }
    return status >= 200 && status < 300
  }

  errorResponse (status, messageGlobal, messageCustom) {
    if (status === 422) {
      EventBus.$emit('showAlert', 'danger', messageGlobal)
    }

    EventBus.$emit('showAlert', 'danger', messageCustom)
  }

  successResponse (message) {
    EventBus.$emit('showAlert', 'success', message)
  }
}
