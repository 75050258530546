import { Color } from '@/util/Color'

class ThemeLoader {
  setSvgClassFilter () {
    setTimeout(() => {
      const fillColor = Color.getFilterForSvgIcon()

      const existingStyleTag = document.querySelector('.dynamic-filter-style')
      if (existingStyleTag) {
        existingStyleTag.remove()
      }
      const dynamicFilterStyleTag = document.createElement('style')
      dynamicFilterStyleTag.classList.add('dynamic-filter-style')
      dynamicFilterStyleTag.textContent = `.svg-dinamic-filter { ${fillColor} }`
      document.head.appendChild(dynamicFilterStyleTag)
    }, 1000)
  }

  loadThemeByHostName () {
    const whiteLabelBrand = window.whiteLabelInfo
    document.body.classList.add(whiteLabelBrand.theme)
    this.setSvgClassFilter()
  }

  getLogoResource (logoFileName) {
    const images = require.context('@/assets/img/logos', false, /\.(png|jpe?g|svg)$/)
    return images(`./${logoFileName}`)
  }

  getImageResource (logoFileName) {
    const images = require.context('@/assets/img/images', false, /\.(png|jpe?g|svg)$/)
    return images(`./${logoFileName}`)
  }

  applyTheme () {
    this.loadThemeByHostName()
    const whiteLabelName = window.whiteLabelInfo.name
    const appTitleText = window.whiteLabelInfo.app_title_text
    document.getElementsByTagName('title')[0].innerText = whiteLabelName + ' ' + appTitleText
  }
}

const themeLoader = new ThemeLoader()

export default themeLoader
