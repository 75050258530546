var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"px-0 mx-0 mt-2"},[_c('v-col',{attrs:{"cols":_vm.disposition ? _vm.disposition[0] : 4}},[_c('validation-provider',{attrs:{"name":_vm.$t('País del destinatario'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"items":_vm.searchedCountriesWithPhonePrefix,"color":"blue-grey lighten-2","background-color":"blue-grey lighten-5","filled":"","item-value":'isoTld',"search-input":_vm.searchInput,"loading":_vm.loading,"no-filter":"","label":_vm.$t('País del destinatario')},on:{"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-avatar',{staticClass:"mx-1",attrs:{"left":"","size":"20"}},[_c('img',{attrs:{"rounded":"0","width":"20","height":"20","src":_vm.Country.getFlag(data.item.isoTld)}})]),_c('span',{staticClass:"selected-item"},[_vm._v(_vm._s(data.item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{attrs:{"rounded":"0","width":"30","height":"30"}},[_c('img',{attrs:{"src":_vm.Country.getFlag(item.isoTld)}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.name)}})],1)]}}],null,true),model:{value:(_vm.isoCode),callback:function ($$v) {_vm.isoCode=$$v},expression:"isoCode"}})]}}])})],1),_c('v-col',{attrs:{"cols":_vm.disposition ? _vm.disposition[1] : 6}},[_c('validation-provider',{attrs:{"name":_vm.$t('número de teléfono'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{staticStyle:{"min-height":"100px"},attrs:{"hide-selected":"","error-messages":errors,"label":_vm.$t('Agrega algún número de teléfono'),"multiple":"","persistent-hint":"","filled":"","append-icon":"","background-color":"blue-grey lighten-5","type":"text"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('v-icon',{on:{"click":function($event){return _vm.removeTag(item)}}},[_vm._v(" fas fa-times ")])],1)]}}],null,true),model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})]}}])})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"theme-primary-btn text-center px-3 ml-n4 mt-1",attrs:{"block":"","large":"","depressed":"","elevation":"0","small":""}},[_vm._v(" "+_vm._s(_vm.$t('Agregar'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }