var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observerSubstitution",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalidSubstitution = ref.invalid;
return [_c('v-dialog',{attrs:{"width":"100%","max-width":"800px"},on:{"click:outside":_vm.closeModal},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"nocard noshadow"},[_c('div',{staticClass:"header_modal gradient-text-color"},[_vm._v(" "+_vm._s(_vm.$t('Sustituir campos variables'))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":_vm.closeModal}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Cerrar')))])])],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-alert',{staticClass:"mt-4",attrs:{"dark":"","type":"info","border":"top"}},[_vm._v(" "+_vm._s(_vm.$t('Se han encontrado'))+" "+_vm._s(_vm.dltVarFields.length)+" "+_vm._s(_vm.$t('campos variables {#var#} en el mensaje.'))+" ")]),_c('p',{staticClass:"mt-3 mx-4",domProps:{"innerHTML":_vm._s(_vm.currentMessage)}}),_c('ul',{staticClass:"mt-3",staticStyle:{"list-style":"none","clear":"both"}},_vm._l((_vm.dltVarFields),function(varField,index){return _c('li',{key:index},[_c('v-row',{staticClass:"mt-3",staticStyle:{"margin-bottom":"-40px"}},[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('campo personalizado'),"rules":{
                        required: true,
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.customFields,"item-text":'text',"item-value":'value',"filled":"","background-color":"blue-grey lighten-5","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" {#var#}"),_c('sup',[_vm._v(_vm._s(index + 1))])]},proxy:true}],null,true),model:{value:(varField.customField),callback:function ($$v) {_vm.$set(varField, "customField", $$v)},expression:"varField.customField"}})]}}],null,true)})],1)],1)],1)}),0)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-7 mt-4 pt-0"},[_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mt-3 my-0",attrs:{"offset":"6","cols":"6"}},[_c('v-spacer'),_c('v-btn',{staticClass:"darken-1  white--text text-capitalize float-right px-5",attrs:{"elevation":"0","large":"","color":"light-blue darken-3","disabled":invalidSubstitution},on:{"click":_vm.acceptModal}},[_vm._v(" "+_vm._s(_vm.$t('Aceptar'))+" ")])],1)],1)],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }