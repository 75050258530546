import User from '@/models/user'
import ButtonMultimedia from '../../../MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import getEnv from '@/util/env'
import { SmsRegExp } from '@/classes/SmsRegExp'
import EventBus from '@/util/EventBus'
import { SmsObject } from '@/classes/SmsObject'
import { v4 as uuidv4 } from 'uuid'

export default {
  props: [
    'label',
    'menuOptionData',
    'synthesisOptions',
    'isPreview',
    'fields',
    'componentsData',
    'campaign',
    'smsPreview',
    'saveBarProperties',
  ],
  name: 'TextToSpeechSource',
  components: { ButtonMultimedia },
  data: function () {
    return {
      timeout: null,
      debouncedInput: '',
      speechOptions: [
        { value: 'speech', text: this.$t('Escribir texto a audio').toString() },
        { value: 'audio', text: this.$t('Seleccionar audio').toString() },
      ],
      regex: SmsRegExp.getRegExp(),
      noAudioUrlHelper: '',
      isSelectingAudio: false,
      aiDrawerCtrl: {
        show: false,
      },
      eventUuid: '',
    }
  },
  computed: {
    message: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.menuOptionData.message = val
          this.getSpeechPreviewUrl()
        // eslint-disable-next-line no-magic-numbers
        }, 700)
      },
    },
    audioFileName: function () {
      const name = this.menuOptionData?.url.substring(this.menuOptionData?.url.lastIndexOf('/') + 1)
      return name.replace(/\.[^.]+/, '')
    },
  },
  methods: {
    openAiAssistant () {
      EventBus.$emit('OpenAiVoiceAssistant', { eventUuid: this.eventUuid })
    },
    setInvalidElements (invalid) {
      if (this.saveBarProperties) {
        this.saveBarProperties.invalid = invalid
      }
    },
    toogleBookMarks () {
      this.campaign.sendings[0].channel.bookmark = !this.campaign.sendings[0].channel.bookmark
    },

    addFieldToMessage (item, type) {
      if (item !== null) {
        let fieldName = item
        if (type === 'field') {
          fieldName = '{' + item + '}'
        }
        if (type === 'last-sms') {
          fieldName = this.componentsData.latestsLocutions[item].message
        }
        if (type === 'bookmark') {
          fieldName = this.componentsData.bookmarks[item].message
        }
        this.insertFieldAtCursor(fieldName)
      }
    },
    insertFieldAtCursor (myValue) {
      const textarea = this.$refs.messageInput.$refs.input
      const sentence = textarea.value
      if (!myValue.length) return

      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.message = before + myValue + after

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + myValue.length
        textarea.selectionEnd = pos + myValue.length
        textarea.click()
      })
    },
    getCurrentFieldsArray (regExp, s) {
      let match = regExp.exec(s)
      const matches = []

      while (match != null) {
        match = match[0]
        matches.push(match.substr(1, (match.length - 2)))
        match = regExp.exec(s)
      }
      return matches
    },

    async getSpeechPreviewUrl () {
      if (this.menuOptionData.type === 'audio') {
        return
      }

      this.menuOptionData.url = ''
      if (this.menuOptionData.message === '') {
        return
      }
      await this.loadPreview()
      const parameters = {
        message: this.transformFieldsToMessage(),
        language: this.menuOptionData.language,
        gender: this.menuOptionData.gender,
      }
      const params = new URLSearchParams(parameters)

      setTimeout(() => {
        const user = new User(JSON.parse(localStorage.getItem('user')))
        const token = user.token
        this.menuOptionData.url = getEnv('backend_endpoint') + 'api/voice/previewPremiumSpeech?' + params + '&token=' + token
        this.menuOptionData.textUrl = this.menuOptionData.url
      })
    },

    async loadPreview () {
      const smsObject = new SmsObject()
      smsObject.message = this.menuOptionData.message
      EventBus.$emit('contactSource:LoadAdditionalSmsPreview', this.campaign, smsObject, this.smsPreview)
    },

    transformFieldsToMessage () {
      if (!this.smsPreview) {
        return this.menuOptionData.message
      }
      const fieldsInMessage = this.getCurrentFieldsArray(this.regex.variablesRegex, this.menuOptionData.message)
      const contactNumbers = Object.keys(this.smsPreview.contactsFieldsValue)
      const object = this.smsPreview.contactsFieldsValue[contactNumbers[0]]
      const contactFields = []
      for (const index in object) {
        contactFields[index] = object[index]
      }
      let message = this.menuOptionData.message

      for (let i = 0; i < fieldsInMessage.length; i++) {
        const field = fieldsInMessage[i]
        const fieldValue = contactFields[field] ? contactFields[field] : this.$t('Sin datos')
        message = message.replace('{' + fieldsInMessage[i] + '}', fieldValue)
      }
      return message
    },
    selectAudioFile (url) {
      if (this.isSelectingAudio) {
        this.isSelectingAudio = false
        this.menuOptionData.url = ''
        setTimeout(() => {
          this.menuOptionData.url = getEnv('backend_endpoint') + url
        })
      }
    },
    updatePreview () {
      this.getSpeechPreviewUrl()
    },
    updateMenuOptionDataPreview () {
      this.menuOptionData.language = this.synthesisOptions.language
      this.menuOptionData.gender = this.synthesisOptions.gender
      this.getSpeechPreviewUrl()
    },
    changeUrlSource () {
        this.menuOptionData.url = ''
        this.message = ''
    },
  },
  watch: {
    'menuOptionData.language': function () {
      this.updatePreview()
    },
    menuOptionData: function () {
      this.updatePreview()
    },
    synthesisOptions: {
      handler: function () {
        this.updateMenuOptionDataPreview()
      },
      deep: true,
    },
  },
  created () {
    this.debouncedInput = this.menuOptionData.message
  },
  mounted () {
    const uuid = uuidv4()
    this.eventUuid = 'ai-voice-text-' + uuid
    EventBus.$on(this.eventUuid, (data) => {
      this.debouncedInput = data
      this.menuOptionData.message = data
      this.getSpeechPreviewUrl()
    })
    EventBus.$on('changePreviewUploadResponseVoiceSms', () => {
      this.loadPreview()
    })
    this.updatePreview()
  },
}
