// asset imports
import '@/assets/scss/argon.scss'
import '@/assets/scss/main-theme.scss'
import '@/assets/scss/test-theme.scss'
import '@/assets/scss/nrsgateway-theme.scss'
import '@/assets/scss/sample-dark-theme.scss'
import '@/assets/scss/sample-green-theme.scss'
import '@/assets/scss/360nrs.scss'
import '@/assets/scss/360nrs-backend.scss'

export default {}
